
import { defineComponent } from 'vue';
export default defineComponent({
  name : 'AuthView',
  props:{
    showBorder:{
      type: Boolean,
      default: true
    }
  }
})
